import { Box, Button, Grid, Group, PasswordInput, Select, TextInput } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { isEmail, useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { IconCheck, IconPhoto, IconUpload, IconX, IconXboxX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import Wilayas from '../../helper/wilayas.json';
import axios from "axios";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const AddRequest = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const {onSubmit, reset, getInputProps, setValues, values, setFieldError} = useForm({
        initialValues: {name: "", email: "", phone: ""},
        validate: {
            name: (value) => (value?.length < 2 ? 'الاسم غير صالح' : null),
            // email: isEmail('هذا البريد غير صالح'),
            // phone: (value: string) => (/^(05|06|07)[0-9]{8}$/.test(value) ? null : 'رقم الهاتف غير صالح'),
        },
        validateInputOnBlur: true,
        validateInputOnChange: true
    });

    const onSubmitForm = ({name, email, phone}: any) => {
        if (/^(05|06|07)[0-9]{8}$/.test(phone) !== true && email.length < 5 ) {
            setFieldError("phone", 'قم بادخال رقم الهاتف او البريد الالكتروني')
            setFieldError("email", 'قم بادخال البريد الالكتروني او رقم الهاتف')
        } else {
            setLoading(true)
            axios.post(`/requests`, {
                "name": name,
                "email": email,
                "phone": phone,
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': cookies.get('token') || ""
                }
            })
            .then(({data}) => {
                console.log(data);
                setLoading(false)
                typeof props?.refetchData == "function" && props?.refetchData()
                closeModal()
            })
            .catch(({response}) => {
                console.log(response)
                setLoading(false)
            });
        }
        
    }

    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading} withCloseButton={false}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        {/* <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{"إلغاء"}</Button> */}
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{"تأكيد"}</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={{padding: 20}}>
                <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                    <Grid gutter={20} justify="center">
                        <Grid.Col span={{base: 12}} >
                            <TextInput
                                label={"الاسم"}
                                placeholder={"الاسم"}
                                withAsterisk
                                {...getInputProps("name")}
                            />
                        </Grid.Col>
                        <Grid.Col span={{base: 12, md: 6}} >
                            <TextInput
                                label={"رقم الهاتف"}
                                placeholder={"رقم الهاتف"}
                                withAsterisk
                                {...getInputProps("phone")}
                            />
                        </Grid.Col>
                        <Grid.Col span={{base: 12, md: 6}} >
                            <TextInput
                                label={"البريد الالكتروني"}
                                placeholder={"البريد الالكتروني"}
                                withAsterisk
                                {...getInputProps("email")}
                            />
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};